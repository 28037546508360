import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import ErrorMessage from '../ErrorMessage'
import CustomDatePicker from '../../FormComponents/CustomDatePicker'
import Loader from '../../Loader'
import isEmail from 'validator/lib/isEmail'

import './styles.scss'

const validateEmail = (enteredEmailAddress) => {
  const isEmailValid = isEmail(enteredEmailAddress)
  if(isEmailValid) {
    return true
  } else {
    // Field error message for email field
    return 'Please enter a correct email address.'
  }
}

// React hook form validation for our form fields.
const VALIDATION_RULES = {
  firstName: {
    required: { value: true, message: 'You missed this field.' }
  },
  lastName: {
    required: { value: true, message: 'You missed this field.' }
  },
  email: {
    required: { value: true,  message: 'You missed this field.' },
    validate: { value: validateEmail }
  },
  phone: {
    required: true,
    pattern: { value: /^\d{10}$/, message: 'Please insert your 10 digit phone number.'}
  },
}

const INPUT_PLACEHOLDER = {
  firstName: '',
  lastName: '',
  email: 'Enter email address',
  phone: '',
}


const CustomerForm = props => {
  const { phone, chainUuid } = props.data
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: phone.replace('+1', '')
  }
  const { register, errors, handleSubmit } = useForm({defaultValues, mode: 'onBlur'})

  // To handle the api request call status.
  const [ requestState, setRequestState ] = useState({
    loading: false,
    error: null,
  })

  // To handle birth day input seelction
  const [birthday, setBirthday] = useState(null)

  const handleFormSubmit = (data) => {
    setRequestState({ loading: true, error: null })
    const payload = {
      'first_name': data.firstName,
      'last_name': data.lastName,
      'phone': `+1${data.phone}`,
      'email' : data.email,
      'uuid': chainUuid,
    }
    if(birthday) {
      payload['birthday'] = moment(birthday).format('MM/DD/YYYY')
    }
    axios.post(process.env.GATSBY_LOYALTY_REGISTRATION_URL, payload)
      .then((response) => {
        setRequestState({ loading: false, error: null })
        // Show success message from parent method, on successful form submission.
        props.toggleSuccessCard(true)
      })
      .catch((error) => {
        setRequestState({ loading: false, error: error })
      })
  }

  /**
   * 
   * @param {*} fieldName input field form name
   * @param {*} label label for input field
   */
  const renderInput = (fieldName, label) => {
    const fieldError = errors[fieldName]
    return (
      <div className='row'>
        <label htmlFor={ fieldName }>{ label }</label>
        <div className={fieldName} >
          <input
            name={ fieldName }
            id={ fieldName }
            placeholder={ INPUT_PLACEHOLDER[fieldName]}
            className = {`${fieldError ? 'error-boundary' : ''}`}
            ref = {register(VALIDATION_RULES[fieldName])}
          />
        </div>
        <ErrorMessage error={fieldError} />
      </div>
    )
  }

  // Check whther api call happened, does it have any error status. it's useful after the from submission api call.
  const requestErrorMessage =
    requestState.error
      ? { message: 'Something went wrong, Please try again.' }
      : null

  return (
    <div className='eg-loyalty-customer-form'>
      <div className='title'>Edit member details</div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        { renderInput('firstName', 'First name') }
        { renderInput('lastName', 'Last name') }
        { renderInput('email', 'Email') }
        { renderInput('phone', 'Phone number') }
        <div className='row'>
          <label htmlFor='birthday'>Birthdate (OPTIONAL)</label>
          <CustomDatePicker 
            className='birthday'
            date={birthday}
            onDateChange={setBirthday}
          />
          <ErrorMessage error={errors['birthday']} />
        </div>
        <div className='actions'>
          {requestState.loading
            ? <Loader />
            : (
              <button
                type='submit'
                className='submit'
              >JOIN NOW </button>
            )
          }
        </div>
        {/* If request fails to do the api call, shwo an error message */}
        <ErrorMessage
          error={requestErrorMessage} 
        />
      </form>
    </div>
  )
}

CustomerForm.propTypes = {
  onSubmit: PropTypes.func,
  toggleSuccessCard: PropTypes.func,
  data: PropTypes.object.isRequired,
}

export default CustomerForm
