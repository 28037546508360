import React, { useState } from 'react'
import PropTypes from 'prop-types'
import querystring from 'query-string'
import { globalHistory } from '@reach/router'

import Card from './Card'
import CustomerForm from './CustomerForm'
import SuccessCard from './SuccessCard'
import './styles.scss'

const LoyaltyIVR = props => {
  const { search: searchParameter } = globalHistory.location
  const queryParameterObj = querystring.parse(searchParameter)
  const { p: phone = '', name = '', c: chainUuid } = queryParameterObj
  const [successCard, toggleSuccessCard] = useState(false)
  const { source } = props

  const sourceLabel = source ? source.replace(/-/g, ' ') : ''

  const renderForm = () => {
    return (
      <>
        <div className='title'>
          Get $25 off when you join <span>{ sourceLabel }</span> Rewards!
        </div>

        <div className='subtitle'>
          Just enter your email to complete your profile.
          Once you opt-in, we’ll get you started with a 25 dollar off discount.
        </div>

        <div className='card'>
          <Card title='$25 off' name={name} phone={phone} />
        </div>

        <div className='customer-info'>
          <CustomerForm 
            toggleSuccessCard={toggleSuccessCard}
            data={
              { 
                phone,
                chainUuid,
              }
            }
          />
        </div>

        <div className='service-info'>
          <p>
            **the 25 dollar off discount will be deducted on your next purchase with
            <span>{ ` ${sourceLabel}` }</span>.
          </p>
          <p>
            By joining this rewards program I give my express written consent to receive marketing
            text or native mobile messages via automated technology (e.g., automatic telephone
            dialing systems) from <span>{ sourceLabel }</span> at the mobile number provided. Up to eight
            messages per month. Consent to receive messages is not a condition of purchasing any property,
            goods or services. Text STOP to end to 244547. MSG & data rates may apply.
            I also agree to the Privacy Policy & Terms & Conditions.
          </p>
        </div>
      </>
    )
  }
 
  return (
    <div className='eg-loyalty-ivr-container'>
      <div className='eg-loyalty-ivr'>
        <div className={ `fp-logo ${source}` } />
        {successCard
          ? <SuccessCard sourceLabel={ sourceLabel } />
          : renderForm()
        }     
      </div>
    </div>
  )
}

LoyaltyIVR.propTypes = {
  source: PropTypes.string
}

export default LoyaltyIVR
