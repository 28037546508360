import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

/**
 * A component to render successful form submission information.
 * @param {*} props 
 */
const SuccessCard = props => {
  const { sourceLabel } = props
  return (
    <div className='success-card'>
      <div className='logo' />
      <div className='title'>
        <div>Well done!</div>
        Your <span>{ sourceLabel }</span> rewards profile is now active
      </div>
      <div className='info'>
        The $25 discount will be applied on your next purchase with <span>{ sourceLabel }</span>.
      </div>
    </div>
  )
}

SuccessCard.propTypes = {
  sourceLabel: PropTypes.string
}

export default SuccessCard
