import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const Card = props => {
  const { title, name, phone } = props

  return (
    <div className='eg-loyalty-card'>
      <div className='card-head'>
        <div className='card-title'>{ title }</div>
      </div>
      <div className='card-body'>
        <div className='customer-name'>{ name }</div>
        <div className='customer-phone'>{ phone }</div>
      </div>
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string
}

export default Card
