import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

/**
 * A component to render error messages for input fields
 * @param {*} props 
 */
const ErrorMessage = props => {
  const { error } = props
  if(!error) {
    return null
  }
  return (
    <div className='error-message'>
      {error.message}
    </div>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.object
}

export default ErrorMessage
