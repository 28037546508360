import React from 'react'
import { Helmet } from 'react-helmet'

import LoyaltyIVR from '../components/LoyaltyIVR'
import ErrorBoundary from '../components/ErrorBoundary'

const LoyaltyIVRContainer = () => {
  return (
    <ErrorBoundary>
      <Helmet title='Frank Pepe Loyalty Signup'/>
      <LoyaltyIVR source='frank-pepe' />
    </ErrorBoundary>
  )
}

export default LoyaltyIVRContainer
